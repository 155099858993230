import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from './config';
import { Table, Modal, Button, Form, FormControl, InputGroup, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './App.css';

ChartJS.register(ArcElement, Tooltip, Legend);

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [data, setData] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [searchStages, setSearchStages] = useState(null);
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState([null]);
  const [lastEvaluatedStage, setLastEvaluatedStage] = useState(null);
  const [searchType, setSearchType] = useState('');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const storedData = sessionStorage.getItem('data');
    const storedLoginState = sessionStorage.getItem('isLoggedIn');

    if (storedLoginState === 'true' && storedData) {
      setIsLoggedIn(true);
      setUsername(sessionStorage.getItem('username') || '');
      setPassword(sessionStorage.getItem('password') || '');
      fetchData(page, perPage, lastEvaluatedKeys[page - 1], lastEvaluatedStage, searchType, searchValue, searchStages);
      fetchMetrics();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchData(page, perPage, lastEvaluatedKeys[page - 1], lastEvaluatedStage, searchType, searchValue, searchStages);
      fetchMetrics();
    }
  }, [page, perPage, isLoggedIn]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${config.conversationlistUrl}`, {
        username,
        password,
        page,
        per_page: perPage,
        last_evaluated_key: lastEvaluatedKeys[page - 1] ? JSON.stringify(lastEvaluatedKeys[page - 1]) : null,
        search_type: searchType || null,
        search_value: searchValue || null,
        search_stages: searchStages,
        last_evaluated_stage: lastEvaluatedStage
      });
      setData(response.data.items);
      setLastEvaluatedKeys(prevKeys => {
        const newKeys = [...prevKeys];
        newKeys[page] = response.data.last_evaluated_key;
        return newKeys;
      });
      setLastEvaluatedStage(response.data.last_evaluated_stage);
      setIsLoggedIn(true);
      sessionStorage.setItem('data', JSON.stringify(response.data.items));
      sessionStorage.setItem('isLoggedIn', 'true');
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('password', password);
      setError('');
      fetchMetrics(); // Fetch metrics after successful login
    } catch (err) {
      setError('Login failed');
      setData([]);
      setIsLoggedIn(false);
      sessionStorage.removeItem('data');
      sessionStorage.removeItem('isLoggedIn');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('password');
    } finally {
      setLoading(false);
    }
  };

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.conversationMetrics}`, {
        username,
        password
      });
      setMetrics(response.data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (page, perPage, lastEvaluatedKey, lastEvaluatedStage, searchType, searchValue, searchStages) => {
    setLoading(true);
    try {
      const stageOrder = ["not_responded", "responded", "not_interested", "interested", "needs_follow_up", "blank"];
      const sortedStages = searchStages ? [...searchStages].sort((a, b) => stageOrder.indexOf(a) - stageOrder.indexOf(b)) : null;
  
      const response = await axios.post(`${config.conversationlistUrl}`, {
        username,
        password,
        page,
        per_page: perPage,
        last_evaluated_key: lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : null,
        search_type: searchType || null,
        search_value: searchValue ? searchValue.trim() : null,
        search_stages: sortedStages || null,
        last_evaluated_stage: lastEvaluatedStage || null,
      });
      setData(response.data.items);
      setLastEvaluatedKeys(prevKeys => {
        const newKeys = [...prevKeys];
        newKeys[page] = response.data.last_evaluated_key;
        return newKeys;
      });
      setLastEvaluatedStage(response.data.last_evaluated_stage);
      sessionStorage.setItem('data', JSON.stringify(response.data.items));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    if (!loading && page > 1) {
      setPage(prevPage => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (!loading && lastEvaluatedKeys[page]) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setData([]);
    setPage(1);
    setSearchType('');
    setSearchValue('');
    setSearchStages(null);
    setLastEvaluatedKeys([null]);
    setLastEvaluatedStage(null);
    sessionStorage.removeItem('data');
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('password');
  };

  const handleRowClick = (item) => {
    if (!loading) {
      setSelectedItem(item);
      setShowModal(true);
    }
  };

  const handleEventClick = (event) => {
    if (!loading) {
      setSelectedEvent(event);
      setShowEventModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleCloseEventModal = () => {
    setShowEventModal(false);
    setSelectedEvent(null);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setPage(1); // Reset to first page
    setLastEvaluatedKeys([null]); // Reset last evaluated keys
    setLastEvaluatedStage(null);
    fetchData(1, perPage, null, lastEvaluatedStage, searchType, searchValue, searchStages);
  };
  
  const handleClear = async () => {
    setSearchType('');
    setSearchValue('');
    setSearchStages(null);
    setPage(1);
    setLastEvaluatedKeys([null]);
    setLastEvaluatedStage(null);
    await fetchData(1, perPage, null, null, '', '', null);
  };
  
  const handleStageSelection = (stage) => {
    setSearchStages((prevStages) => {
      if (prevStages) {
        if (prevStages.includes(stage)) {
          return prevStages.filter(s => s !== stage);
        } else {
          return [...prevStages, stage];
        }
      } else {
        return [stage];
      }
    });
  };
  
  const renderAttributes = (item) => {
    return Object.keys(item).map((key) => {
      if (key !== 'chats' && key !== 'events' && key !== 'name_lower' && key !== 'email_lower' && key !== 'phone_lower') {
        return (
          <div className="item" key={key}>
            <strong>{key}:</strong> {key === 'muted' ? String(item[key]) : item[key]}
          </div>
        );
      }
      return null;
    });
  };

  const getChatbotName = (item) => item.chatbotName || "AI";
  const getName = (item) => item.name || "User";

  const formatDateToEST = (datetime) => {
    const date = new Date(datetime);
    const options = { timeZone: 'America/New_York', hour12: true, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const estTime = date.toLocaleString('en-US', options);
    return `${estTime} EST`;
  };

  const renderUpdates = (updates) => {
    return (
      <pre>
        {JSON.stringify(updates, null, 2)}
      </pre>
    );
  };

  const renderRequestBody = (requestBody) => {
    return (
      <pre>
        {JSON.stringify(requestBody, null, 2)}
      </pre>
    );
  };

  const renderMetrics = () => {
    if (!metrics) return null;
  
    const { total_items, stage_counts } = metrics;
    const data = {
      labels: Object.keys(stage_counts),
      datasets: [
        {
          label: 'Stage Distribution',
          data: Object.values(stage_counts),
          backgroundColor: [
            '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
          ],
        },
      ],
    };
  
    return (
      <div className="metrics-container">
        <h3 className="metrics-title">Metrics</h3>
        <p>Total Leads: {total_items}</p>
        {Object.entries(stage_counts).map(([stage, count]) => (
          <p key={stage}>{`${stage}: ${count}`}</p>
        ))}
        <Pie data={data} width={95} height={95} />
      </div>
    );
  };

  if (!isLoggedIn) {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <label>
            Username:
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </label>
          <br />
          <label>
            Password:
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>
          <br />
          <Button type="submit" disabled={loading}>Login</Button>
        </form>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    );
  }

  return (
    <div className="App">
      {loading && <div className="spinner"></div>} {/* Show spinner when loading */}
      <div className="user-logout-container">
        <span className="username-display">{username}</span> {/* Display the username */}
        <Button className="logout-button" onClick={handleLogout} disabled={loading}>Logout</Button>
      </div>
      <div className="main-container">
        <div className="metrics-section">
          {renderMetrics()}
        </div>
        <div className="table-container">
          <h3 className="leads-title">Leads</h3>
          <div className="search-container">
            <Form onSubmit={handleSearch} className="search-form">
              <InputGroup className="mb-3">
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={searchType || 'Search by...'}
                  id="input-group-dropdown-1"
                >
                  <Dropdown.Item onClick={() => setSearchType('name')}>Name (full match)</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSearchType('email')}>Email</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSearchType('phone')}>Phone</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSearchType('site')}>Site</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSearchType('conversationid')}>Conversation ID</Dropdown.Item>
                </DropdownButton>
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value.trimStart())}
                />

                <Dropdown as={InputGroup.Prepend}>
                  <Dropdown.Toggle variant="outline-secondary" id="input-group-dropdown-2">
                    Stages
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {["not_responded", "responded", "not_interested", "interested", "needs_follow_up", "blank"].map(stage => (
                      <Dropdown.Item
                        key={stage}
                        as="div" // Changed from "button" to "div" to avoid interference with the default behavior
                        className="dropdown-item-checkbox"
                        onClick={(e) => e.stopPropagation()} // Stop the default dropdown behavior
                      >
                        <label 
                          htmlFor={`stage-checkbox-${stage}`} 
                          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%', margin: '0', padding: '0'}}
                        >
                          <input
                            id={`stage-checkbox-${stage}`}
                            type="checkbox"
                            checked={searchStages && searchStages.includes(stage)}
                            onChange={() => handleStageSelection(stage)}
                            style={{ marginRight: '8px', cursor: 'pointer' }}
                          />
                          {stage}
                        </label>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Button type="submit" disabled={loading || 
                  (!(searchType && searchValue.trim()) && (!searchStages || searchStages.length === 0))
                }>Search</Button>
                <Button variant="secondary" onClick={handleClear} disabled={loading || (!searchType && !searchValue.trim() && !searchStages)}>Clear</Button>
              </InputGroup>
            </Form>
          </div>
          <div className="table-wrapper">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Site</th>
                  <th>Stage</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.site}</td>
                    <td>{item.stage}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div>
            <button onClick={handlePrevious} disabled={loading || page === 1}>
              Previous
            </button>
            <span> Page {page} </span>
            <button onClick={handleNext} disabled={loading || !lastEvaluatedKeys[page]}>
              Next
            </button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered dialogClassName="modal-80w modal-80h">
        <Modal.Header closeButton>
          <Modal.Title>Conversation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <div style={{ display: 'flex', width: '100%' }}>
              <div className="modal-section modal-section-attributes">
                <h5>Attributes</h5>
                {renderAttributes(selectedItem)}
              </div>
              <div className="modal-section modal-section-chats">
                <h5>Chats</h5>
                {selectedItem.chats && selectedItem.chats.map((chat, index) => (
                  <div key={index}>
                    <div className={`chat-message ${chat.role === 'user' ? 'chat-user' : 'chat-assistant'}`}>
                      <div>{chat.content}</div>
                    </div>
                    {chat.role === 'user' ? (
                      <div className="chat-label-user">
                        {getName(selectedItem)} - {formatDateToEST(chat.datetime)}
                      </div>
                    ) : (
                      <div className="chat-label-ai">
                        {getChatbotName(selectedItem)} - {formatDateToEST(chat.datetime)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="modal-section modal-section-events">
                <h5>Events</h5>
                {selectedItem.events ? (
                  selectedItem.events.map((event, index) => (
                    <div
                      key={index}
                      className={`item event-message ${event.inbound ? 'event-inbound' : 'event-outbound'}`}
                      onClick={() => handleEventClick(event)}
                    >
                      <div className="event-arrow">
                        {event.inbound ? '->' : '<-'}
                      </div>
                      <div className="event-content">
                        <div className="event-type">
                          {event.event_type}
                        </div>
                        <div className="event-datetime">{formatDateToEST(event.datetime)}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No events available</p>
                )}              
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={loading}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEventModal} onHide={handleCloseEventModal} centered dialogClassName="modal-4-5 event-details-modal">
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div>
              <div><strong>Event Type:</strong> {selectedEvent.event_type}</div>
              <div><strong>Datetime:</strong> {formatDateToEST(selectedEvent.datetime)}</div>
              <div><strong>Path:</strong> <a href={selectedEvent.path} target="_blank" rel="noopener noreferrer">{selectedEvent.path}</a></div>
              <div><strong>Trigger:</strong> {selectedEvent.request_body?.trigger}</div>
              {selectedEvent.event_type === 'Conversation:Updated' ? (
                <div><strong>Updates:</strong> {renderUpdates(selectedEvent.request_body?.updates)}</div>
              ) : selectedEvent.event_type === 'Response:Created' ? (
                <div><strong>Response Text:</strong> {selectedEvent.request_body?.response?.text || 'N/A'}</div>
              ) : (
                <div><strong>Request Body:</strong> {renderRequestBody(selectedEvent.request_body)}</div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEventModal} disabled={loading}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
